import {
  Box,
  Button,
  Container,
  Grid,
  List,
  ListItem,
  Typography,
  useTheme,
} from "@mui/material";
import { PricingCard } from "./pricing-card";
import CheckCircleTwoToneIcon from "@mui/icons-material/CheckCircleTwoTone";
import WashingMachines from "../../assets/images/image_12.jpeg";

export interface PricingData {
  title: string;
  subtitle: string;
  pricePerColdWash: string; // Changed from pricePerCycle
  pricingPerWarmHotWash: string;
  temperature: string[];
  earnRewardPoints: boolean;
  complimentary60DegreesMachineRinse?: boolean;
  discounted?: boolean;
  antiWrinkle?: boolean;
  type: "wash" | "dry";
}

const pricingHighlights: string[] = [
  "week’s laundry done in just one hour – fast, easy, efficient!",
  "from small to extra-large, our washers and dryers fit all your laundry needs!",
  "laundry made easy, with eco-friendly machines that care for your clothes and the planet!",
  "wash and dry duvets, pillows, bedding, and everything you can’t handle at home!",
];

const selfServeWashPricing: PricingData[] = [
  {
    title: "14 kg washer",
    subtitle:
      "ideal for weekly washes for 2-3 people and smaller bedding items",
    pricePerColdWash: "$2.60",
    pricingPerWarmHotWash: "$3.10",
    temperature: ["normal/cold - 20°c", "warm - 40°c", "hot - 60°c"],
    complimentary60DegreesMachineRinse: true,
    discounted: true,
    earnRewardPoints: true,
    type: "wash",
  },
  {
    title: "23 kg washer",
    subtitle: "ideal for weekly washes for 3-4 people and larger bedding items",
    pricePerColdWash: "$3.60",
    pricingPerWarmHotWash: "$4.10",
    temperature: ["normal/cold - 20°c", "warm - 40°c", "hot - 60°c"],
    complimentary60DegreesMachineRinse: true,
    discounted: true,
    earnRewardPoints: true,
    type: "wash",
  },
  {
    title: "27 kg washer",
    subtitle: "ideal for weekly washes for 4-7 people and larger bedding items",
    pricePerColdWash: "$4.60",
    pricingPerWarmHotWash: "$5.10",
    temperature: ["normal/cold - 20°c", "warm - 40°c", "hot - 60°c"],
    complimentary60DegreesMachineRinse: true,
    discounted: true,
    earnRewardPoints: true,
    type: "wash",
  },
];

const selfServeDryPricing: PricingData[] = [
  {
    title: "14kg dryer",
    subtitle:
      "ideal for drying clothing for 1-3 people and smaller bedding items.",
    pricePerColdWash: "$4.20",
    pricingPerWarmHotWash: "32 minutes",
    temperature: [
      "high 87°c",
      "medium 82°c",
      "low 71°c",
      "delicates 54°c",
      "no heat",
    ],
    earnRewardPoints: true,
    type: "dry",
  },
  {
    title: "21kg dryer",
    subtitle:
      "ideal for drying clothing for 1-3 people and smaller bedding items.",
    pricePerColdWash: "$5.00",
    pricingPerWarmHotWash: "30 minutes",
    temperature: [
      "high 87°c",
      "medium 82°c",
      "low 71°c",
      "delicates 54°c",
      "no heat",
    ],
    earnRewardPoints: true,
    antiWrinkle: true,
    type: "dry",
  },
];

export const Pricing = () => {
  const theme = useTheme();

  return (
    <Box>
      <Grid container>
        <Grid item xs={12} md={6} sx={{ display: "flex" }}>
          <Box
            component={"img"}
            src={WashingMachines}
            alt={"Pricing"}
            width={"100%"}
            maxHeight={550}
            sx={{ objectFit: "cover" }}
          />
        </Grid>
        <Grid item xs={12} md={6} sx={{ display: "flex" }}>
          <Box
            width={"100%"}
            sx={{ backgroundColor: theme.palette.primary.dark }}
            p={theme.spacing(4)}
            color={theme.palette.common.white}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
          >
            <Typography variant="h4" fontWeight={600}>
              pricing
            </Typography>
            <List>
              {pricingHighlights.map((highlight, index) => (
                <ListItem key={index} sx={{ p: 0, my: 3 }}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <CheckCircleTwoToneIcon
                      fontSize="large"
                      sx={{ marginRight: 2, color: theme.palette.common.white }}
                    />
                    {highlight}
                  </Box>
                </ListItem>
              ))}
            </List>
          </Box>
        </Grid>
      </Grid>
      <Container maxWidth={"lg"} sx={{ py: theme.spacing(5) }}>
        <Typography variant="h4" fontWeight={600}>
          self-serve wash pricing
        </Typography>
        <Typography>
          do your week’s worth of washing in 24 minutes with our huge 10kg-20kg
          front load washers. premium plant-based detergent is dispensed
          automatically and included in the price.
        </Typography>

        <Grid container spacing={6} my={theme.spacing(5)}>
          {selfServeWashPricing.map((data, index) => (
            <Grid item xs={12} md={4} key={index} display={"flex"}>
              <PricingCard data={data} />
            </Grid>
          ))}
        </Grid>
        <Typography variant="h4" fontWeight={600}>
          self-serve dry pricing
        </Typography>
        <Typography>
          enjoy the convenience of huge gas dryers that give your textiles
          plenty of room to tumble for the perfect drying experience every time.
        </Typography>

        <Grid container spacing={6} my={theme.spacing(5)}>
          {selfServeDryPricing.map((data, index) => (
            <Grid item xs={12} md={6} key={index} display={"flex"}>
              <PricingCard data={data} />
            </Grid>
          ))}
        </Grid>
      </Container>
      <Box sx={{ backgroundColor: theme.palette.primary.dark }}>
        <Container
          maxWidth={"lg"}
          sx={{ py: theme.spacing(8), color: theme.palette.common.white }}
        >
          <Typography variant="h3" fontWeight={600} my={theme.spacing(4)}>
            join our loyalty program
          </Typography>
          <Typography variant="body1">
            transform your laundry day – with state-of-the-art Speed Queen
            washers and dryers, effortlessly connected through the Speed Queen
            mobile app for unmatched convenience..
          </Typography>

          <List>
            {[
              "live machine status & laundry notifications.",
              "once you’ve completed $80 worth of washes or dries, you’ll receive $8 back in your account on us!",
              "contactless convenient payment, right from the app.",
            ].map((highlight, index) => (
              <ListItem key={index} sx={{ p: 0, my: 3 }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <CheckCircleTwoToneIcon
                    fontSize="large"
                    sx={{ marginRight: 2, color: theme.palette.common.white }}
                  />
                  {highlight}
                </Box>
              </ListItem>
            ))}
          </List>
          <Button
            variant="contained"
            color="secondary"
            sx={{ textTransform: "none" }}
          >
            <Typography sx={{ color: "white", fontWeight: 600 }}>
              join now
            </Typography>
          </Button>
        </Container>
      </Box>
    </Box>
  );
};
