import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import Wifi from "../../../common/svg/wifi";
import Cheap from "../../../common/svg/cheap";
import Quality from "../../../common/svg/quality";
import Convenient from "../../../common/svg/convenient";
import { Card } from "./card";

export interface Highlight {
  title: string;
  description: string;
  icon: JSX.Element;
}

const highlights: Highlight[] = [
  {
    title: "convenient",
    description:
      "our mobile app makes it easy to schedule a pickup and delivery.",
    icon: <Convenient />,
  },
  {
    title: "affordable",
    description: "our prices are competitive and transparent.",
    icon: <Cheap />,
  },
  {
    title: "quality",
    description: "we take pride in our work and guarantee your satisfaction.",
    icon: <Quality />,
  },
  {
    title: "free wifi",
    description: "enjoy free wifi while you wait for your laundry.",
    icon: <Wifi />,
  },
];

export const SmartwashDifference = () => {
  const theme = useTheme();

  return (
    <Container maxWidth="lg">
      <Box
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        py={theme.spacing(10)}
      >
        <Typography variant={"h4"} fontWeight={600}>
          discover the
          <Typography
            component={"span"}
            color={theme.palette.secondary.main}
            variant={"h4"}
            fontWeight={600}
          >
            {" "}
            e-smartwash{" "}
          </Typography>
          revolution!
        </Typography>
        <Typography color={"text.main"} fontSize={20} my={theme.spacing(3)}>
          we’re revolutionizing laundry with eco-friendly technology, offering
          clean, convenient, and sustainable care.
        </Typography>

        <Grid
          container
          spacing={8}
          display={"flex"}
          alignItems={"start"}
          justifyContent={"start"}
          my={theme.spacing(4)}
        >
          {highlights.map((highlight, idx) => (
            <Grid
              item
              md={3}
              sm={12}
              display={"flex"}
              flexDirection={"column"}
              alignItems={"center"}
              textAlign={"center"}
              key={highlight.title}
              width={"100%"}
            >
              <Card highlight={highlight} />
            </Grid>
          ))}
        </Grid>

        <Button
          LinkComponent={"a"}
          href="/pricing"
          variant="outlined"
          sx={{ my: theme.spacing(2), color: theme.palette.secondary.main }}
        >
          view our pricing
        </Button>
      </Box>
    </Container>
  );
};
