import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Phone from "../../common/svg/phone";
import Pin from "../../common/svg/pin";
import CreditCard from "../../common/svg/credit-card";
import Apple from "../../common/svg/apple";
import Playstore from "../../common/svg/playstore";
import Machines from "../../assets/images/image_5.jpeg";
import Machines2 from "../../assets/images/image_11.jpeg";
import PaymentsImage from "../../assets/images/image_12.jpeg";
import Baskets from "../../assets/images/image_6.jpeg";
import { useRef } from "react";

interface CallToAct {
  title: string;
  subtitle: string;
  icon: JSX.Element;
  children: JSX.Element[];
}

const prompts: CallToAct[] = [
  {
    title: "download & sign up",
    subtitle:
      "download and sign up to the speedqueen app on ios or android using one of the buttons below. Click the button that matches your device to get started.",
    icon: <Phone />,
    children: [
      <Box
        marginTop={3}
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          gap: 4,
        }}
      >
        <a
          href="https://apps.apple.com/us/app/speed-queen/id1235121730"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Apple />
        </a>
        <a
          href="https://play.google.com/store/apps/details?id=com.alliancelaundry.app.speedqueen&hl=en_NZ&pli=1"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Playstore />
        </a>
      </Box>,
    ],
  },
  {
    title: "select your location",
    subtitle: "select your e-smartwash location by entering the code below.",
    icon: <Pin />,
    children: [
      <Box
        marginTop={3}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          gap: 4,
        }}
      >
        {" "}
        <Typography fontWeight={600}>
          <Typography component={"span"}>ESMART</Typography>{" "}
        </Typography>
      </Box>,
    ],
  },
  {
    title: "add funds",
    subtitle:
      "add funds to your account using a credit card or apple pay/google pay. with every $10 spent, you will earn 1 reward point.",
    icon: <CreditCard />,
    children: [],
  },
];

export const LoyaltyProgram = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const downloadSectionRef = useRef<HTMLDivElement>(null);

  const scrollToDownloadSection = () => {
    downloadSectionRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <Box>
      <Grid container sx={{ backgroundColor: theme.palette.primary.dark }}>
        <Grid item xs={12} md={6} display={"flex"} flexDirection={"column"}>
          <Box
            component={"img"}
            src={Machines}
            alt={"Pricing"}
            width={"100%"}
            height={550}
            sx={{ objectFit: "cover" }}
          />
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          display={"flex"}
          flexDirection={"column"}
          sx={{ color: theme.palette.common.white }}
          justifyContent={"center"}
        >
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            padding={theme.spacing(4)}
          >
            <Typography
              variant="h3"
              color={theme.palette.common.white}
              fontWeight={600}
            >
              loyalty program
            </Typography>
            <Typography>
              experience the future of laundry with our cutting-edge Speed Queen
              washers and dryers! Paired with the <b>Speed Queen mobile app</b>,
              you'll enjoy an effortless, user-friendly experience that rewards
              you with every wash.{" "}
              <b>
                Earn points toward free cycles and elevate your laundry game
                like never before!
              </b>
            </Typography>

            <Box my={theme.spacing(4)}>
              <Button
                variant="contained"
                color="secondary"
                size="large"
                sx={{ mr: theme.spacing(2), marginTop: theme.spacing(2) }}
                onClick={scrollToDownloadSection}
              >
                join loyalty program
              </Button>
              <Button
                size="large"
                color="primary"
                variant="outlined"
                sx={{ marginTop: theme.spacing(2) }}
              >
                program benefits
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Box sx={{ backgroundColor: theme.palette.grey[100] }}>
        <Container maxWidth={"lg"} sx={{ py: theme.spacing(5) }}>
          <Typography variant="h4" fontWeight={600}>
            <Typography
              variant="h4"
              fontWeight={600}
              sx={{ color: theme.palette.secondary.main }}
              component={"span"}
            >
              unlock exclusive benefits:
            </Typography>{" "}
            join our loyalty program and start enjoying rewards
          </Typography>

          <Grid container spacing={4} my={theme.spacing(5)}>
            {prompts.map((prompt, index) => (
              <Grid
                item
                xs={12}
                md={4}
                key={prompt.title}
                sx={{ marginTop: matches ? theme.spacing(3) : "" }}
                ref={index === 0 ? downloadSectionRef : undefined}
              >
                <Box
                  width={"100%"}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  {prompt.icon}
                  <Typography
                    variant="h5"
                    fontWeight={600}
                    sx={{ color: theme.palette.secondary.main }}
                    my={theme.spacing(2)}
                  >
                    {prompt.title}
                  </Typography>
                  <Typography textAlign={"center"}>
                    {prompt.subtitle}
                  </Typography>
                  {prompt.children}
                </Box>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
      <Box>
        <Container maxWidth={"lg"} sx={{ py: theme.spacing(5) }}>
          <Typography variant="h4" fontWeight={600}>
            <Typography
              variant="h4"
              fontWeight={600}
              sx={{ color: theme.palette.secondary.main }}
              component={"span"}
            >
              save time & money
            </Typography>{" "}
            with our easy to use speedqueen app
          </Typography>
          <Grid container sx={{ my: theme.spacing(5) }} spacing={6}>
            <Grid item xs={12} md={5}>
              <Box
                sx={{
                  width: "100%",
                  height: "400px",
                  backgroundColor: theme.palette.secondary.main,
                  objectFit: "cover",
                }}
                component={"img"}
                src={Baskets}
              ></Box>
            </Grid>
            <Grid item xs={12} md={7}>
              <Box
                width={"100%"}
                height={"100%"}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Typography
                  variant="h5"
                  fontWeight={600}
                  sx={{ color: theme.palette.secondary.main }}
                  my={theme.spacing(2)}
                >
                  reward yourself
                  <Typography
                    variant="h5"
                    fontWeight={600}
                    sx={{ color: theme.palette.common.black }}
                    my={theme.spacing(2)}
                    component={"span"}
                  >
                    {" "}
                    with a free wash or dry
                  </Typography>
                </Typography>
                <Typography>
                  tap, wash, and earn! Set up your e-smartwash account in
                  seconds and start racking up rewards. Spend $80, and we’ll
                  give you $8 back – it’s our way of saying thanks! Fast,
                  efficient, and easy on your wallet.
                </Typography>
                <Box my={theme.spacing(4)}>
                  <Button
                    variant="contained"
                    color="secondary"
                    size="large"
                    sx={{ mr: theme.spacing(2) }}
                    onClick={scrollToDownloadSection}
                  >
                    join loyalty program
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Grid container sx={{ my: theme.spacing(5) }} spacing={6}>
            <Grid item xs={12} md={7}>
              <Box
                width={"100%"}
                height={"100%"}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Typography
                  variant="h5"
                  fontWeight={600}
                  my={theme.spacing(2)}
                  sx={{ color: theme.palette.common.black }}
                >
                  convenient
                  <Typography
                    variant="h5"
                    fontWeight={600}
                    sx={{ color: theme.palette.secondary.main }}
                    my={theme.spacing(2)}
                    component={"span"}
                  >
                    {" "}
                    contactless payment
                  </Typography>
                </Typography>
                <Typography>
                  no more scrambling for loose change! The Speed Queen mobile
                  app lets you pay for your laundry with just a tap on your
                  phone, making the process smooth and hassle-free. Prefer using
                  a card? Our advanced washers and dryers are fully equipped
                  with card readers, offering you multiple payment options.
                  We’ve thought of every detail to ensure your laundry day is as
                  convenient as possible, saving you time and effort. Now, you
                  can skip the payment fuss and focus on what really matters –
                  enjoying your day while we handle the rest.
                </Typography>
                <Box my={theme.spacing(4)}>
                  <Button
                    variant="contained"
                    color="secondary"
                    size="large"
                    sx={{ mr: theme.spacing(2) }}
                    onClick={scrollToDownloadSection}
                  >
                    join loyalty program
                  </Button>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={5}>
              <Box
                sx={{
                  width: "100%",
                  height: "400px",
                  backgroundColor: theme.palette.secondary.main,
                  objectFit: "cover",
                }}
                component={"img"}
                src={PaymentsImage}
              ></Box>
            </Grid>
          </Grid>
          <Grid container sx={{ my: theme.spacing(5) }} spacing={6}>
            <Grid item xs={12} md={5}>
              <Box
                sx={{
                  width: "100%",
                  height: "400px",
                  backgroundColor: theme.palette.secondary.main,
                  objectFit: "cover",
                }}
                component={"img"}
                src={Machines2}
              ></Box>
            </Grid>
            <Grid item xs={12} md={7}>
              <Box
                width={"100%"}
                height={"100%"}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Typography
                  variant="h5"
                  fontWeight={600}
                  sx={{ color: theme.palette.secondary.main }}
                  my={theme.spacing(2)}
                >
                  live machine status
                  <Typography
                    variant="h5"
                    fontWeight={600}
                    sx={{ color: theme.palette.common.black }}
                    my={theme.spacing(2)}
                    component={"span"}
                  >
                    {" "}
                    + laundry notifications
                  </Typography>
                </Typography>
                <Typography>
                  tired of making a trip to the laundromat only to find that all
                  the machines are in use? fear not, because with the speed
                  queen app, you can check the availability of our self-serve
                  washers and dryers right from the palm of your hand! the app
                  also lets you know when your laundry is almost finished, so
                  you can plan your time accordingly.
                </Typography>
                <Box my={theme.spacing(4)}>
                  <Button
                    variant="contained"
                    color="secondary"
                    size="large"
                    sx={{ mr: theme.spacing(2) }}
                    onClick={scrollToDownloadSection}
                  >
                    join loyalty program
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};
