import { Box, Card, Divider, Typography, useTheme } from '@mui/material';
import { PricingData } from '.';
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';

const CustomDivider = () => {
  const theme = useTheme();
  return (
    <Divider
      sx={{
        margin: theme.spacing(2, 0),
        backgroundColor: theme.palette.common.white,
      }}
    />
  );
};
export const PricingCard = ({ data }: { data: PricingData }) => {
  const theme = useTheme();
  return (
    <Card sx={{ backgroundColor: theme.palette.primary.dark, width: '100%' }}>
      <Box
        p={theme.spacing(2)}
        textAlign={'center'}
        color={theme.palette.common.white}
      >
        <Typography
          variant='h6'
          fontWeight={600}
          mb={theme.spacing(2)}
          color={theme.palette.secondary.main}
        >
          {data.title}
        </Typography>
        <Typography variant='subtitle1' fontWeight={400}>
          {data.subtitle}
        </Typography>
        <CustomDivider />
        <Typography>{data.type === "wash" ? "cold wash" : "price"} : {data.pricePerColdWash}</Typography>
        <CustomDivider />
        <Typography>{data.type === "wash" ? "warm/hot wash" : "cycle time"} : {data.pricingPerWarmHotWash}</Typography>
        <Divider
          sx={{
            margin: theme.spacing(2, 0),
            backgroundColor: theme.palette.common.white,
          }}
        />
        temperature:
        {data.temperature.map((temperature) => (
          <Typography key={temperature}>{temperature}</Typography>
        ))}
        <CustomDivider />
        {data.complimentary60DegreesMachineRinse && (
          <>
            <Box
              display={'flex'}
              justifyContent={'space-between'}
              alignItems={'center'}
            >
              <>
                <Typography>Complimentary 60° machine rinse</Typography>
                <CheckCircleTwoToneIcon
                  sx={{ color: theme.palette.common.white }}
                  fontSize='large'
                />
              </>
            </Box>
            <CustomDivider />
          </>
        )}
        {data.discounted && (
          <>
            <Box
              display={'flex'}
              justifyContent={'space-between'}
              alignItems={'center'}
            >
             October Discounted
              <CheckCircleTwoToneIcon
                sx={{ color: theme.palette.common.white }}
                fontSize='large'
              />
            </Box>
            <CustomDivider />
          </>
        )}
        {data.earnRewardPoints && (
          <Box
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            Earn reward points
            <CheckCircleTwoToneIcon
              sx={{ color: theme.palette.common.white }}
              fontSize='large'
            />
          </Box>
        )}
      </Box>
    </Card>
  );
};
