import { Box, Container, Grid, Typography, useTheme } from '@mui/material';
import Machines1 from '../../assets/images/image_11.jpeg';
import Machines2 from '../../assets/images/image_10.jpeg';
import Machines from '../../assets/images/image_15.jpeg';

interface MoreInfo {
  title: string;
  image: string;
}

const moreInfo: MoreInfo[] = [
  {
    title: 'our mission',
    image: Machines1,
  },
  {
    title: 'sustainability',
    image: Machines2,
  },
  {
    title: 'blog',
    image: Machines,
  },
];

export const MoreInformation = () => {
  const theme = useTheme();
  return (
    <Box sx={{ backgroundColor: theme.palette.grey[100] }}>
      <Container maxWidth='lg'>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            py: theme.spacing(10),
          }}
        >
          <Typography
            variant={'h4'}
            fontWeight={600}
            textAlign={'center'}
            width={'100%'}
            pb={theme.spacing(8)}
            color={theme.palette.primary.main}
          >
            more
            <Typography
              component={'span'}
              variant={'h4'}
              fontWeight={600}
              color={theme.palette.secondary.main}
            >
              {' '}
              information{' '}
            </Typography>
          </Typography>

          <Grid container spacing={4}>
            {moreInfo.map((info) => (
              <Grid item key={info.title} xs={12} md={4}>
                <Box
                  display={'flex'}
                  flexDirection={'column'}
                  alignItems={'center'}
                  height={500}
                  sx={{
                    backgroundColor: theme.palette.primary.main,
                  }}
                >
                  <Box
                    component={'img'}
                    src={info.image}
                    alt={info.title}
                    width={'100%'}
                    height={500}
                    sx={{ objectFit: 'cover' }}
                  />
                  <Typography
                    variant={'h4'}
                    fontWeight={600}
                    textAlign={'center'}
                    color={'white'}
                    position={'absolute'}
                    marginTop={'250px'}
                    sx={{
                      textShadow: '0px 4px 4px rgba(0, 0, 0, 0.45)',
                    }}
                  >
                    {info.title}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};
