import * as React from 'react';
import { SVGProps } from 'react';
const Apple = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    xmlSpace='preserve'
    width={64}
    height={64}
    viewBox='0 0 512 512'
    {...props}
  >
    <path d='M472 0H40C17.9 0 0 17.9 0 40v432c0 22.1 17.9 40 40 40h432c22.1 0 40-17.9 40-40V40c0-22.1-17.9-40-40-40zM265.1 142.1c9.4-11.4 25.4-20.1 39.1-21.1 2.3 15.6-4.1 30.8-12.5 41.6-9 11.6-24.5 20.5-39.5 20-2.6-14.9 4.4-30.2 12.9-40.5zm84.3 197.8c-10.8 16.4-26 36.9-44.9 37.1-16.8.2-21.1-10.9-43.8-10.8-22.7.1-27.5 11-44.3 10.8-18.9-.2-33.3-18.7-44.1-35.1-30.2-46-33.4-99.9-14.7-128.6 13.2-20.4 34.1-32.3 53.8-32.3 20 0 32.5 11 49.1 11 16 0 25.8-11 48.9-11 17.5 0 36 9.5 49.2 26-43.2 23.7-36.2 85.4 7.5 101.9-6.1 13.2-9 19.2-16.7 31z' />
  </svg>
);
export default Apple;
