import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import Form, { Input, SubmitButton, TextArea } from 'react-form-component';
import { LocationsView } from '../../common/components/locations';

export const Contact = () => {
  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Box>
      <Box
        sx={{
          backgroundColor: theme.palette.primary.dark,
          color: theme.palette.common.white,
        }}
      >
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            md={6}
            display={'flex'}
            flexDirection={'column'}
            alignItems={matches ? 'flex-start' : 'flex-end'}
          >
            <Box
              sx={{ padding: matches ? theme.spacing(2) : theme.spacing(10) }}
            >
              <Typography variant='h3' fontWeight={600}>
                get in touch
              </Typography>
              <Typography variant='h5' fontWeight={600} mt={theme.spacing(3)}>
                phone
              </Typography>
              <Typography mt={theme.spacing(2)}>(+64) 22 529 7735</Typography>
              <Typography variant='h5' fontWeight={600} mt={theme.spacing(3)}>
                email
              </Typography>
              <Typography mt={theme.spacing(2)}>admin@e-smartwash.co.nz</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              sx={{ padding: matches ? theme.spacing(2) : theme.spacing(10) }}
            >
              <Form
                fields={[
                  'firstName',
                  'lastName',
                  'emailAddress',
                  'phone',
                  'message',
                ]}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Input name='firstName' label='First Name' />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Input name='lastName' label='last Name' />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Input
                      name='emailAddress'
                      label='Email Address'
                      type='email'
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Input name='phone' label='Phone' initialValue={''} />
                  </Grid>
                  <Grid item xs={12}>
                    <TextArea name='message' label='Message' mandatory />
                  </Grid>
                </Grid>
                <SubmitButton onClick={(values) => console.log(values)}>
                  Submit
                </SubmitButton>
              </Form>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box textAlign={'center'} py={theme.spacing(6)}>
        <Typography
          variant={'h4'}
          fontWeight={600}
          color={theme.palette.secondary.main}
          py={theme.spacing(5)}
        >
          e-smartwash
          <Typography
            component={'span'}
            variant={'h4'}
            fontWeight={600}
            color={theme.palette.common.black}
          >
            {' '}
            location
          </Typography>
        </Typography>
        <LocationsView />
      </Box>
    </Box>
  );
};
