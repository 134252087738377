import {
  Box,
  Container,
  Divider,
  Grid,
  Link,
  List,
  ListItem,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import XIcon from '@mui/icons-material/X';

interface Location {
  title: string;
  address1: string;
  address2: string;
}

const locations: Location[] = [
  {
    title: 'Hamilton East',
    address1: '5/5 Peach Grove Road',
    address2: 'Hamilton, NZ',
  },
];

interface Contact {
  title: string;
  details: string;
}

const contacts: Contact[] = [
  {
    title: 'phone',
    details: '022-529-7734',
  },
  {
    title: 'email',
    details: 'admin@e-smartwash.co.nz',
  },
];

interface OpeningHours {
  day: string;
  hours: string;
  lastWash: string;
}

const openingHours: OpeningHours[] = [
  {
    day: 'Monday-Friday',
    hours: '9:00am - 10:00pm',
    lastWash: '9:45pm',
  },
  {
    day: 'Saturday',
    hours: '9:00am - 10:00pm',
    lastWash: '9:45pm',
  },
  {
    day: 'Sunday',
    hours: '9:00am - 10:00pm',
    lastWash: '9:45pm',
  },
];

interface UsefulLink {
  title: string;
  url: string;
}

const usefulLinks: UsefulLink[] = [
  {
    title: 'about us',
    url: '/about',
  },
  {
    title: 'contact us',
    url: '/contact',
  },
  {
    title: 'blog',
    url: '/blog',
  },
];

interface SocialLink {
  title: string;
  icon: JSX.Element;
  url: string;
}

const socialLinks: SocialLink[] = [
  {
    title: 'facebook',
    icon: <FacebookIcon />,
    url: 'https://www.facebook.com',
  },
  {
    title: 'instagram',
    icon: <InstagramIcon />,
    url: 'https://www.instagram.com',
  },
  {
    title: 'x',
    icon: <XIcon />,
    url: 'https://www.twitter.com',
  },
];

export const AppFooter = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <footer>
      <Box
        sx={{
          width: '100%',
          backgroundColor: theme.palette.primary.main,
          py: theme.spacing(6),
        }}
      >
        <Container maxWidth='lg' sx={{ p: theme.spacing(4, 2) }}>
          <Grid container>
            <Grid item xs={12} sm={6} md={3}>
              <Box
                display={'flex'}
                flexDirection={'column'}
                alignItems={matches ? 'center' : ''}
              >
                <Typography
                  variant='h5'
                  color={theme.palette.common.white}
                  fontWeight={600}
                  textAlign={'center'}
                >
                  Locations
                </Typography>

                <List>
                  {locations.map((location) => (
                    <ListItem key={location.title} sx={{ textAlign: 'center' }}>
                      <Box lineHeight={1.5} width={'100%'}>
                        <Typography
                          variant='body1'
                          color={theme.palette.common.white}
                          fontWeight={600}
                        >
                          {location.title}
                        </Typography>
                        <Typography
                          variant='body2'
                          color={theme.palette.common.white}
                        >
                          {location.address1}
                        </Typography>
                        <Typography
                          variant='body2'
                          color={theme.palette.common.white}
                        >
                          {location.address2}
                        </Typography>
                      </Box>
                    </ListItem>
                  ))}
                </List>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Box
                display={'flex'}
                flexDirection={'column'}
                alignItems={matches ? 'center' : ''}
              >
                <Typography
                  variant='h5'
                  color={theme.palette.common.white}
                  fontWeight={600}
                  textAlign={'center'}
                >
                  Reach us
                </Typography>
                <List>
                  {contacts.map((contact) => (
                    <ListItem key={contact.title} sx={{ textAlign: 'center' }}>
                      <Box lineHeight={1.5} width={'100%'}>
                        <Typography
                          variant='body1'
                          color={theme.palette.common.white}
                          fontWeight={600}
                        >
                          {contact.title}
                        </Typography>
                        <Typography
                          variant='body2'
                          color={theme.palette.common.white}
                        >
                          {contact.details}
                        </Typography>
                      </Box>
                    </ListItem>
                  ))}
                </List>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Box
                display={'flex'}
                flexDirection={'column'}
                alignItems={matches ? 'center' : ''}
              >
                <Typography
                  variant='h5'
                  color={theme.palette.common.white}
                  fontWeight={600}
                  textAlign={'center'}
                >
                  Opening hours
                </Typography>
                <List>
                  {openingHours.map((hours) => (
                    <ListItem key={hours.day} sx={{ textAlign: 'center' }}>
                      <Box lineHeight={1.5} width={'100%'}>
                        <Typography
                          variant='body1'
                          color={theme.palette.common.white}
                          fontWeight={600}
                        >
                          {hours.day}
                        </Typography>
                        <Typography
                          variant='body2'
                          color={theme.palette.common.white}
                        >
                          {hours.hours}
                        </Typography>
                        <Typography
                          variant='body2'
                          color={theme.palette.common.white}
                        >
                          Last wash: {hours.lastWash}
                        </Typography>
                      </Box>
                    </ListItem>
                  ))}
                </List>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Box
                display={'flex'}
                flexDirection={'column'}
                alignItems={matches ? 'center' : ''}
              >
                <Typography
                  variant='h5'
                  color={theme.palette.common.white}
                  fontWeight={600}
                  textAlign={'center'}
                >
                  Useful links
                </Typography>
                <List>
                  {usefulLinks.map((link) => (
                    <ListItem key={link.title} sx={{ textAlign: 'center' }}>
                      <Box lineHeight={1.5} width={'100%'}>
                        <Link
                          href={link.url}
                          color={theme.palette.common.white}
                        >
                          <Typography
                            variant='body1'
                            color={theme.palette.common.white}
                            fontWeight={600}
                          >
                            {link.title}
                          </Typography>
                        </Link>
                      </Box>
                    </ListItem>
                  ))}
                </List>
              </Box>
            </Grid>
          </Grid>
          <Divider
            sx={{
              m: theme.spacing(6, 0, 2, 0),
              borderColor: 'rgba(255, 255, 255, 0.5)',
            }}
          />
          {/* <hr /> */}
          <Grid container sx={{ color: theme.palette.common.white }}>
            <Grid item xs={12} md={3}>
              <Typography variant='body2'>
                copyright 2024 © e-smartwash ltd
              </Typography>
            </Grid>
            <Grid item xs={12} md={9}>
              <Box display={'flex'} justifyContent={'flex-end'}>
                {socialLinks.map((social) => (
                  <Box key={social.title} mx={theme.spacing(2)}>
                    {social.icon}
                  </Box>
                ))}
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </footer>
  );
};
