import { Box, Container, Typography, useTheme } from '@mui/material';
import { LocationsView } from '../../common/components/locations';

export const Locations = () => {
  const theme = useTheme();
  return (
    <Container maxWidth='lg'>
      <Box
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
        py={theme.spacing(12)}
      >
        <Typography variant={'h4'} fontWeight={600}>
          explore our
          <Typography
            component={'span'}
            color={theme.palette.secondary.main}
            variant={'h4'}
            fontWeight={600}
          >
            {' '}
            locations{' '}
          </Typography>
        </Typography>
        <Typography color={'text.main'} fontSize={20} my={theme.spacing(3)}>
          We have a few locations in New Zealand, each with its own unique vibe.
          Check out our locations below.
        </Typography>
        <Box
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          width={'100%'}
          mt={theme.spacing(5)}
        >
          <LocationsView />
        </Box>
      </Box>
    </Container>
  );
};
