import { Box } from '@mui/material';
import { Hero } from './hero';
import { SmartwashDifference } from './difference';
import { MoreInformation } from './more-info';
import { Locations } from './locations';

export const Home = () => {
  return (
    <Box>
      <Hero />
      <SmartwashDifference />
      <MoreInformation />
      <Locations />
    </Box>
  );
};
