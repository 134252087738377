import * as React from 'react';
import { SVGProps } from 'react';
const Iphone = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    id='\u0421\u043B\u043E\u0439_1'
    width={64}
    height={64}
    fill='#000'
    stroke='#000'
    strokeWidth={0.001}
    data-name='\u0421\u043B\u043E\u0439 1'
    viewBox='0 0 128 128'
    {...props}
  >
    <g id='SVGRepo_iconCarrier'>
      <defs>
        <style>{'.cls-2{fill:#404040}'}</style>
      </defs>
      <title />
      <path
        d='M98 52.91V9.75A7.9 7.9 0 0 0 90 2H38a7.9 7.9 0 0 0-8 7.75v108.5a7.9 7.9 0 0 0 8 7.75h52a7.9 7.9 0 0 0 8-7.75Z'
        style={{
          fill: '#fff',
        }}
      />
      <path
        d='M90 128H38a9.89 9.89 0 0 1-10-9.75V9.75A9.89 9.89 0 0 1 38 0h52a9.89 9.89 0 0 1 10 9.75v108.5A9.89 9.89 0 0 1 90 128ZM38 4a5.89 5.89 0 0 0-6 5.75v108.5a5.89 5.89 0 0 0 6 5.75h52a5.89 5.89 0 0 0 6-5.75V9.75A5.89 5.89 0 0 0 90 4Z'
        className='cls-2'
      />
      <path
        d='M75.05 12h-22.1C50.22 12 48 9.43 48 6.28V2.49h4v3.79c0 1 .57 1.72.95 1.72h22.1c.38 0 .95-.69.95-1.72V3.11h4v3.17C80 9.43 77.78 12 75.05 12Z'
        className='cls-2'
      />
    </g>
  </svg>
);
export default Iphone;
