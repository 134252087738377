import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider } from '@mui/material';
import { theme } from './theme';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Root } from './routes/root';
import { ErrorPage } from './error-page';
import { Home } from './routes/home';
import { Pricing } from './routes/pricing';
import { Contact } from './routes/contact';
import { FormThemeProvider } from 'react-form-component';
import { LoyaltyProgram } from './routes/loyalty-program';
import { About } from './routes/about';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '/',
        element: <Home />,
      },
      {
        path: 'pricing',
        element: <Pricing />,
      },
      {
        path: 'contact',
        element: <Contact />,
      },
      {
        path: 'about',
        element: <About />,
      },
      {
        path: 'loyalty-program',
        element: <LoyaltyProgram />,
      },
    ],
  },
]);

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <FormThemeProvider
        theme={{
          sizes: {
            borderRadius: 5,
          },
          colors: {
            accent: theme.palette.secondary.main,
          },
        }}
      >
        <RouterProvider router={router} />
      </FormThemeProvider>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
