import {
  Box,
  Typography,
  Button,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import Laundromat from "../../assets/images/laundromat.jpg";
import { useNavigate } from 'react-router-dom';

export const Hero = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const navigate = useNavigate();

  return (
    <Box>
      <Box
        height={matches ? theme.spacing(60) : "auto"}
        sx={{
          backgroundImage: `url(${Laundromat})`,
          backgroundSize: "cover",
        }}
        width={"100%"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        flexDirection={"column"}
      >
        <Box
          sx={{ maxWidth: theme.spacing(100) }}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          flexDirection={"column"}
          color={theme.palette.common.white}
          padding={theme.spacing(4)}
        >
          <Typography
            variant="h3"
            fontWeight={800}
            textAlign={"center"}
            width={"100%"}
            sx={{
              textShadow: "2px 2px 4px rgba(0,0,0,0.5)",
              padding: theme.spacing(2),
            }}
          >
            🎉 HALF-PRICE  – ALL OCTOBER! 🚨 NOW OPEN – LIMITED TIME OFFER! 🚨
            
          </Typography>

          <Button
            variant="contained"
            size="large"
            sx={{
              marginTop: theme.spacing(2),
              backgroundColor: theme.palette.secondary.main,
            }}
            endIcon={<AttachMoneyIcon />}
            onClick={() => navigate('/pricing')}
          >
            view our pricing 
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
