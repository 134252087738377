import * as React from 'react';
import { SVGProps } from 'react';
const CreditCard = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={64}
    height={64}
    stroke='#000'
    strokeWidth={0}
    viewBox='0 0 32 32'
    {...props}
  >
    <title>{'credit-card'}</title>
    <path
      stroke='none'
      d='M0 26.016q0 .832.576 1.408t1.44.576h28q.8 0 1.408-.576T32 26.016v-20q0-.832-.576-1.408T30.016 4h-28q-.832 0-1.44.608T0 6.016v20zm2.016 0V12h28v14.016h-28zM2.016 8V6.016h28V8h-28zM4 24h4v-1.984H4V24zm0-4h6.016v-5.984H4V20zm6.016 4h4v-1.984h-4V24zM16 24h4v-1.984h-4V24zm6.016 0H24v-1.984h-1.984V24zm4 0H28v-1.984h-1.984V24z'
    />
  </svg>
);
export default CreditCard;
