import { Box, Card, Container, Grid, Link, Typography, useTheme, useMediaQuery } from '@mui/material';
import WashingMachines2 from '../../assets/images/image_14.jpeg';

interface Location {
  title: string;
  image: string;
  phone: string;
  hours: string[];
  address: string[];
}

const locations: Location[] = [
  {
    title: 'Hamilton',
    image: WashingMachines2,
    phone: '+64 22 529 7734',
    hours: ['mon - sun: 9am - 10pm', 'last wash: 10pm'],
    address: ['5/5 Peachgrove Road', 'hamilton east 3216', '[get directions]'],
  },
  // {
  //   title: 'Auckland',
  //   image: WashingMachines,
  //   phone: '+61 423 356 919',
  //   hours: ['mon - sun: 6am - 11pm', 'last wash: 10pm'],
  //   address: ['192 bondi road', 'bondi nsw 2026', '[get directions]'],
  // },
];

export const LocationsView = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Container maxWidth='md'>
      <Box display="flex" justifyContent="center">
        <Grid container spacing={isMobile ? 4 : 8} justifyContent="center">
          {locations.map((location) => (
            <Grid item key={location.image} xs={12} sm={10} md={8} lg={6}>
              <Card sx={{ backgroundColor: theme.palette.primary.dark }}>
                <Box
                  component={'img'}
                  src={location.image}
                  height={isMobile ? 150 : 200}
                  width={'100%'}
                  sx={{
                    objectFit: 'cover',
                  }}
                />
                <Box
                  sx={{
                    p: theme.spacing(isMobile ? 2 : 3),
                    color: theme.palette.common.white,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    textAlign: 'center',
                  }}
                >
                  <Link href={`/locations/${location.title}`} underline="none">
                    <Typography
                      variant={isMobile ? 'h6' : 'h5'}
                      fontWeight={600}
                      color={theme.palette.secondary.main}
                    >
                      {location.title}
                    </Typography>
                  </Link>
                  <Typography fontWeight={600} mt={isMobile ? 1 : 2}>phone</Typography>
                  <Typography>{location.phone}</Typography>
                  <Typography mt={isMobile ? 1 : 2} fontWeight={600}>hours</Typography>
                  <Box>
                    {location.hours.map((hour) => (
                      <Typography key={hour} variant={isMobile ? 'body2' : 'body1'}>{hour}</Typography>
                    ))}
                  </Box>
                  <Typography mt={isMobile ? 1 : 2} fontWeight={600}>address</Typography>
                  <Box>
                    {location.address.map((line) => (
                      <Typography key={line} variant={isMobile ? 'body2' : 'body1'}>{line}</Typography>
                    ))}
                  </Box>
                </Box>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
};
