import { Box, Typography, useTheme } from '@mui/material';
import { Highlight } from '.';
import { useState } from 'react';
import { animated, useSpring } from 'react-spring';

export const Card = ({ highlight }: { highlight: Highlight }) => {
  const theme = useTheme();
  const [isHovered, setIsHovered] = useState(false);
  const styles = useSpring({
    borderRadius: isHovered ? theme.spacing(0) : theme.spacing(2),
    backgroundColor: isHovered
      ? theme.palette.secondary.main
      : theme.palette.primary.main,
  });

  const { x } = useSpring({
    from: { x: 0 },
    x: isHovered ? 5 : 0,
    config: { tension: 500, friction: 10 },
  });

  const transform = x
    .to({
      range: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 1],
      output: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 1],
    })
    .to((x) => `translate3d(${x}px, 0, 0)`);

  return (
    <Box width={'100%'} height={'100%'}>
      <animated.div
        style={styles}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <Box
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'center'}
          alignItems={'center'}
          color={'white'}
          p={theme.spacing(4, 2)}
        >
          <Box
            width={theme.spacing(20)}
            height={theme.spacing(20)}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <animated.div style={{ transform }}>{highlight.icon}</animated.div>
          </Box>
          <Typography variant={'h6'} fontWeight={600} my={theme.spacing(2)}>
            {highlight.title}
          </Typography>
          <Typography
            color={'text.main'}
            variant='body1'
            lineHeight={1.2}
            fontWeight={100}
          >
            {highlight.description}
          </Typography>
        </Box>
      </animated.div>
    </Box>
  );
};
