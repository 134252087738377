import * as React from 'react';
const Convenient = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    id='a'
    width={80}
    height={80}
    fill='#fff'
    stroke='#fff'
    strokeWidth={1.488}
    viewBox='0 0 48 48'
  >
    <g id='SVGRepo_iconCarrier'>
      <defs>
        <style>
          {
            '.d{fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round}'
          }
        </style>
      </defs>
      <g id='b'>
        <path
          id='c'
          d='M40.5 5.5h-33a2 2 0 0 0-2 2v33a2 2 0 0 0 2 2h33a2 2 0 0 0 2-2v-33a2 2 0 0 0-2-2Z'
          className='d'
        />
      </g>
      <path
        d='M5.5 24h37M24 34.31h-.1a4.502 4.502 0 0 1-8.9-.944 4.5 4.5 0 0 1 8.9-.945h.1M24 42.5v-8.189M24 32.42V24M24 13.69h.1a4.502 4.502 0 0 1 8.9.944 4.5 4.5 0 0 1-8.9.945H24M24 5.5v8.189M24 15.579V24'
        className='d'
      />
    </g>
  </svg>
);
export default Convenient;
