import * as React from 'react';
const SvgComponent = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={80}
    height={80}
    fill='none'
    stroke='#fff'
    strokeWidth={0}
    viewBox='0 0 24 24'
  >
    <path
      fill='#fff'
      stroke='none'
      d='M1.333 8.074a1 1 0 1 0 1.334 1.49l-1.334-1.49Zm20 1.49a1 1 0 1 0 1.334-1.49l-1.334 1.49ZM12 19a1 1 0 1 0 0 2v-2Zm.01 2a1 1 0 1 0 0-2v2Zm2.68-3.96a1 1 0 0 0 1.346-1.48l-1.346 1.48Zm3.364-3.7a1 1 0 0 0 1.346-1.48l-1.346 1.48Zm-10.09 2.22a1 1 0 0 0 1.345 1.48l-1.345-1.48ZM4.6 11.86a1 1 0 1 0 1.346 1.48L4.6 11.86ZM2.667 9.566A13.944 13.944 0 0 1 12 6V4A15.944 15.944 0 0 0 1.333 8.074l1.334 1.49ZM12 6c3.586 0 6.856 1.347 9.333 3.565l1.334-1.49A15.944 15.944 0 0 0 12 4v2Zm0 15h.01v-2H12v2Zm0-5c1.037 0 1.98.393 2.69 1.04l1.346-1.48A5.982 5.982 0 0 0 12 14v2Zm0-5c2.332 0 4.455.886 6.054 2.34l1.346-1.48A10.964 10.964 0 0 0 12 9v2Zm-2.69 6.04A3.982 3.982 0 0 1 12 16v-2a5.982 5.982 0 0 0-4.036 1.56l1.345 1.48Zm-3.364-3.7A8.964 8.964 0 0 1 12 11V9a10.964 10.964 0 0 0-7.4 2.86l1.346 1.48Z'
    />
  </svg>
);
export default SvgComponent;
