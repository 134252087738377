import {
  AppBar,
  Box,
  Container,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  useTheme,
} from '@mui/material';
import { Outlet } from 'react-router-dom';
import ESmartwashLogo from '../common/svg/logo';
import { AppFooter } from './footer';
import MenuIcon from '@mui/icons-material/Menu';
import { useState } from 'react';

interface Route {
  name: string;
  url: string;
}

const routes: Route[] = [
  { name: 'Home', url: '/' },
  // { name: 'Locations', url: '/locations' },
  { name: 'Pricing', url: '/pricing' },
  // { name: 'Live status', url: '/live-status' },
  { name: 'Loyalty program', url: '/loyalty-program' },
  { name: 'About', url: '/about' },
  { name: 'Contact', url: '/contact' },
];

const NavigationMenu = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        gap: 2,
        marginLeft: 'auto',
      }}
    >
      {routes.map((route) => (
        <Link href={route.url} key={route.name}>
          <Typography variant='button' fontWeight={600}>
            {route.name}
          </Typography>
        </Link>
      ))}
    </Box>
  );
};

export const Root = () => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <AppBar position='static'>
        <Container maxWidth='xl'>
          <Toolbar disableGutters>
            <Box my={theme.spacing(2)} component={'a'} href='/'>
              <ESmartwashLogo />
            </Box>

            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
              <NavigationMenu />
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                justifyContent: 'flex-end',
                display: { xs: 'flex', md: 'none' },
              }}
            >
              <IconButton
                size='large'
                aria-label='account of current user'
                aria-controls='menu-appbar'
                aria-haspopup='true'
                onClick={handleMenu}
                color='inherit'
              >
                <MenuIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                {routes.map((route) => (
                  <MenuItem key={route.name}>
                    <Box
                      component={'a'}
                      href={route.url}
                      sx={{ textDecoration: 'none' }}
                    >
                      {route.name}
                    </Box>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Outlet />
      <AppFooter />
    </Box>
  );
};
