import { Box, Typography } from '@mui/material';
import { useRouteError } from 'react-router-dom';

export const ErrorPage = () => {
  const error = useRouteError();
  console.log(error);

  return (
    <Box id='error-page'>
      <Typography variant='h1'>Oops!</Typography>
      <Typography variant='h2'>
        Sorry about that, something went wrong.
      </Typography>
      {/* <Typography variant='body1'>Error: {(error as any).message}</Typography> */}
    </Box>
  );
};
