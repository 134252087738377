import {
  Box,
  Button,
  Container,
  Grid,
  List,
  ListItem,
  Typography,
  useTheme,
} from "@mui/material";
import CheckCircleTwoToneIcon from "@mui/icons-material/CheckCircleTwoTone";
import Machines from "../../assets/images/image_2.jpeg";
import Machines2 from "../../assets/images/image_5.jpeg";
import { Link as RouterLink } from "react-router-dom";

const prompts = [
  "stay in the loop with Live Machine Status and real-time laundry notification.",
  "after completing a set amount of washes or dries, you'll get a credit back in your account, on us!",
  "contactless convenient payment, right from the app.",
];

export const About = () => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        backgroundImage: `linear-gradient(to right, rgba(56, 86, 101, 0.8), rgba(56, 86, 101, 0.8)), url('${Machines2}')`,
        backgroundSize: "cover",
      }}
    >
      <Grid container>
        <Grid item xs={12} md={6} sx={{ display: "flex" }}>
          <Box
            component={"img"}
            src={Machines}
            alt={"Pricing"}
            width={"100%"}
            maxHeight={550}
            sx={{ objectFit: "cover" }}
          />
        </Grid>
        <Grid item xs={12} md={6} sx={{ display: "flex" }}>
          <Box
            width={"100%"}
            sx={{ backgroundColor: theme.palette.primary.dark }}
            p={theme.spacing(4)}
            color={theme.palette.common.white}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
          >
            <Typography variant="h4" fontWeight={600}>
              about
            </Typography>

            <Typography>
              E-SmartWash Laundromat is more than just laundry – it’s an
              ultra-convenient spot to work while you wait. With free Wi-Fi,
              comfortable seating, and a cozy atmosphere, getting your laundry
              done has never been this productive
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Box
        sx={{
          backgroundColor: theme.palette.common.white,
        }}
      >
        <Container maxWidth="lg">
          <Grid container>
            <Grid
              item
              xs={12}
              md={6}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Typography
                variant="h4"
                fontWeight={600}
                color={theme.palette.secondary.main}
              >
                redefining laundromats{" "}
                <Typography
                  component={"span"}
                  fontWeight={600}
                  variant="h4"
                  color={theme.palette.text.primary}
                >
                  to be the most comfortable, easy-to-use, and environmentally
                  friendly.
                </Typography>
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} sx={{ display: "flex" }}>
              <Box
                width={"100%"}
                p={theme.spacing(4)}
                display={"flex"}
                flexDirection={"column"}
                justifyContent={"center"}
              >
                <Typography>
                  <b>
                    Ever stepped into a laundromat and felt disappointed? We
                    know the feeling. That’s when we had an idea: why not
                    reinvent the local self-serve laundromat?
                  </b>
                </Typography>
                <Typography mt={4}>
                  Our mission was clear – to create a space that’s not just
                  spotless and easy to use but inviting for everyone. We wanted
                  to make laundry a more enjoyable experience for our customers
                  and the planet.
                </Typography>
                <Typography mt={4}>
                  So, we got to work and built a premium, thoughtfully crafted
                  self-serve laundromat where you can wash and dry without any
                  hassle or worry. Plus, we’ve created a cozy spot where you can
                  work comfortably, enjoy free Wi-Fi, and even catch up on your
                  favorite shows with our TV. We’re excited to introduce you to
                  this refreshing space and hope you love it as much as we do.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Container
        maxWidth="lg"
        sx={{
          p: 8,
        }}
      >
        <Typography
          variant="h4"
          fontWeight={600}
          sx={{ color: theme.palette.common.white }}
        >
          get started, join our loyalty program
        </Typography>

        <Typography sx={{ color: theme.palette.common.white, mt: 3 }}>
          Elevate your laundry experience - our locations use the most advanced
          Speed Queen washers and dryers, seamlessly synched with the Speed
          Queen mobile app.
        </Typography>

        <List>
          {prompts.map((prompt) => (
            <ListItem key={prompt}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <CheckCircleTwoToneIcon
                  sx={{ color: theme.palette.common.white }}
                  fontSize="large"
                />
                <Typography
                  key={prompt}
                  sx={{ color: theme.palette.common.white, marginLeft: 2 }}
                >
                  {prompt}
                </Typography>
              </Box>
            </ListItem>
          ))}
        </List>

        <Button
          component={RouterLink}
          to="/loyalty-program"
          variant="contained"
          color="secondary"
          sx={{ mt: 2 }}
        >
          <Typography sx={{ color: "white", fontWeight: 600 }}>
            join for free
          </Typography>
        </Button>
      </Container>
    </Box>
  );
};
