import * as React from 'react';
const Quality = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    xmlSpace='preserve'
    width={80}
    height={80}
    fill='#fff'
    stroke='#fff'
    strokeWidth={0.256}
    viewBox='0 0 64 64'
  >
    <path d='M54 32.6v8.6c-4.4.7-6.8.1-9.3-.6-3.1-.8-6.3-1.7-12.9-.5-4.9.9-7.4.2-10-.5-2.9-.8-5.9-1.6-11.8-.6v-6.4c0-3.1-2.5-5.6-5.6-5.6H4v2h.4c2 0 3.6 1.6 3.6 3.6v22.9c0 3.1 2.5 5.6 5.6 5.6h36.9c3.1 0 5.6-2.5 5.6-5.6V32.6c0-2 1.6-3.6 3.6-3.6h.3v-2h-.4c-3.1 0-5.6 2.5-5.6 5.6zM50.4 59H13.6c-2 0-3.6-1.6-3.6-3.6V41c5.7-.9 8.5-.2 11.3.6 1.8.5 3.7 1 6.3 1 1.3 0 2.8-.1 4.6-.5l-.2-1 .2 1c6.2-1.1 9.2-.3 12 .4 1.8.5 3.7 1 6.3 1 1 0 2.2-.1 3.5-.3v12.3c0 1.9-1.6 3.5-3.6 3.5z' />
    <path d='M30.7 46.8H29l-3.2 4.9v1h3.5v2h1.4v-2h1v-1.1h-1v-4.8zm-1.4 2.6v2.1h-2.1l1.4-2.1c.2-.4.4-.8.7-1.3v1.3zM35.3 46.7c-1.8 0-2.9 1.6-2.9 4.1 0 2.4 1 4 2.8 4 1.9 0 2.8-1.6 2.8-4.1 0-2.4-.9-4-2.7-4zm-.1 6.9c-.8 0-1.3-1-1.3-2.9 0-2 .5-2.9 1.3-2.9.9 0 1.3 1.1 1.3 2.9 0 1.8-.4 2.9-1.3 2.9z' />
  </svg>
);
export default Quality;
